// @ts-nocheck

const isProduction = process.env.NODE_ENV === 'production';

export const cc = {
  color: 'rgb(238,82,82)',
  production: isProduction,
  api: {
    url: isProduction ? process.env.API_HOST || 'https://api.cloudwaitress.com' : 'http://localhost:3010',
  },
};
