import axios from 'axios';
import { cc } from './config';

export class APIStore {
  token: string = '';

  // API REQUEST HELPERS
  request = async (args: T.API.RequestArgs): Promise<any> => {
    const { path, data, params } = args;
    // axios.defaults.timeout = 60 * 20 * 1000; //20 minutes
    try {
      const res = await axios({
        baseURL: `${cc.api.url}/`,
        url: path,
        method: 'post',
        headers: {
          Authorization: this.token,
        },
        params: params || {},
        data: data || {},
      });
      return res.data;
    } catch (e) {
      // @ts-ignore
      if (e.response && e.response.data) {
        // @ts-ignore
        if (e.response.status === 401) {
          throw new Error('invalid-auth');
        } else {
          // @ts-ignore
          throw e.response.data;
        }
      } else {
        throw e;
      }
    }
  };

  getRequest = async (args: T.API.RequestArgs): Promise<any> => {
    const { path, data, params } = args;
    // axios.defaults.timeout = 60 * 20 * 1000; //20 minutes
    try {
      const res = await axios({
        baseURL: `${cc.api.url}/`,
        url: path,
        method: 'get',
        headers: {
          Authorization: this.token,
        },
        params: params || {},
        data: data || {},
      });
      return res.data;
    } catch (e) {
      // @ts-ignore
      if (e.response && e.response.data) {
        // @ts-ignore
        if (e.response.status === 401) {
          throw new Error('invalid-auth');
        } else {
          // @ts-ignore
          throw e.response.data;
        }
      } else {
        throw e;
      }
    }
  };

  login = async (data: T.API.AdminLoginRequest): Promise<T.API.AdminLoginResponse> =>
    this.request({
      path: '/admin/login',
      data,
    });

  accounts = async (): Promise<T.API.AdminAccountsResponse> =>
    this.request({
      path: '/admin/accounts',
      // data: data,
    });

  loginUser = async (data: T.API.AdminLoginUserRequest): Promise<T.API.AdminLoginUserResponse> =>
    this.request({
      path: '/admin/user/login',
      data,
    });

  updateResellerSettings = async (data: any) =>
    this.request({
      path: '/admin/update-reseller-settings',
      data,
    });

  updateRestaurantSettings = async (data: any) =>
    this.request({
      path: '/admin/update-restaurant-settings',
      data,
    });

  getInvoiceData = async (data: any) =>
    this.request({
      path: '/admin/get-invoice-data',
      data,
    });

  postInvoiceData = async (data: any) =>
    this.request({
      path: '/admin/post-invoice-data',
      data,
    });

  postResellerInvoiceData = async (data: any) =>
    this.request({
      path: '/admin/post-reseller-invoice-data',
      data,
    });

  getMasterData = async (data: any) =>
    this.request({
      path: '/admin/get-reseller-billing-data',
      data,
    });

  getBaseRestaurantData = async (data: any) =>
    this.request({
      path: '/admin/restaurants',
      data,
    });

  postMenuCopyData = async (data: any) =>
    this.request({
      path: '/admin/copy-menu',
      data,
    });

  updateResellerBilling = async (data: any) =>
    this.request({
      path: '/admin/update-reseller-billing-settings',
      data,
    });

  getResellers = async () =>
    this.getRequest({
      path: 'admin/reseller-admin/get-resellers',
    });

  getRestaurants = async () =>
    this.getRequest({
      path: 'admin/reseller-admin/get-restaurants',
    });

  getOrganizations = async () =>
    this.getRequest({
      path: `admin/reseller-admin/get-organizations`,
    });

  getStaffAccount = async (params?: any) =>
    this.getRequest({
      path: `admin/reseller-admin/get-staff-accounts?org_ids=${params}`,
    });

  restaurantTransfer = async (data: any) =>
    this.request({
      path: 'admin/restaurant/transfer',
      data,
    });
}

export const api = new APIStore();
