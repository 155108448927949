/// <reference types="@lib/types" />
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

function renderApp() {
  const root = document.getElementById('root');
  ReactDOM.render(<App />, root);
}

window.onload = renderApp;
