import React from 'react';
import styled from 'styled-components';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham';
import { RotateLoader } from '@lib/components';
import { cloneDeepSafe } from '@lib/common';
import { api } from '../../api';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 42%;
  margin: 0 auto;
`;

const TableWrapper = styled.div`
  margin-top: 20px;
  width: auto;
`;

const RestaurantTitle = styled.span`
  font-weight: 600;
  font-size: 26px;
  display: block;
`;

const PrevewButton = styled.button`
  height: 35px;
  background-color: #6558f5;
  color: #ffffff;
  border-radius: 5px;
  margin-left: auto;
  min-width: 60px;
  padding: 5px 10px;
  font-size: 14px;
`;

const BackButton = styled.button`
  min-width: 50px;
  height: 35px;
  background-color: #6558f5;
  color: #ffffff;
  border-radius: 5px;
  font-size: 14px;
`;

const ButtonWrapper = styled.div`
  margin-top: 70px;
  display: flex;
`;

interface Props {
  processedData: [];
  setPage: any;
  transferType: string;
  onTransferSuccess: any;
}

class PreviewTransfer extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      staffAccounts: [],
      gridTransferLoading: false,
    };
  }

  processedData: any;

  payload: any = {};

  columnRestaurantToTransferDefs: any = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Subdomain',
      field: 'subdomain',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'From Reseller',
      field: 'reseller_id',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'To Reseller',
      field: 'to_reseller',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'From Org',
      field: 'user',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'To Org',
      field: 'to_orgranization',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
  ];

  columnStaffAccountsDefs: any = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
  ];

  defaultColDefProperty = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    resizable: true,
    floatingFilter: true,
  };

  handleData() {
    const transferData: any = JSON.parse(localStorage.getItem('transferData') as string);
    this.processedData = cloneDeepSafe(this.props.processedData);
    if (transferData) {
      this.processedData.forEach((item: any) => {
        if (this.props.transferType === 'to_organization') {
          item.to_orgranization = transferData[0].email;
          item.to_reseller = item.reseller_id;
          this.payload.transferTo = transferData[0].organisation_id;
        } else {
          item.to_reseller = transferData[0]._id;
          item.to_orgranization = item.user;
          this.payload.transferTo = transferData[0]._id;
        }
        return item;
      });
    } else {
      this.processedData.forEach((item: any) => {
        item.to_reseller = item.reseller_id;
        item.to_orgranization = item.organisation_id;
        return item;
      });
    }
  }

  async componentDidMount() {
    if (this.props.transferType === 'to_reseller') {
      this.setState({ gridTransferLoading: true });
      const arrOrgID = this.processedData.map((item: any) => item.organisation_id);
      const orgs = await api.getStaffAccount(arrOrgID);
      this.setState({ staffAccounts: orgs.organizations, gridTransferLoading: false });
    }
  }

  async restaurantTransfer() {
    this.setState({ loading: true });
    this.payload.restaurants = this.processedData.map((item: any) => item._id);
    this.payload.transferType = this.props.transferType;

    try {
      const result = await api.restaurantTransfer(this.payload);
      if (result.outcome === 1) {
        alert(' Cannot transfer restaurant');
      } else {
        localStorage.removeItem('selectedRs');
        localStorage.removeItem('transferData');
        this.props.onTransferSuccess && this.props.onTransferSuccess();
        this.props.setPage(1);
      }
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }

  computedStaffGridHeight = () => {
    let height = '';
    let domlayout = 'autoHeight';

    if (this.state.staffAccounts.length > 5) {
      height = '250px';
      domlayout = 'normal';
    }

    return {
      height,
      domlayout,
    };
  };

  render() {
    this.handleData();
    const { height, domlayout } = this.computedStaffGridHeight();
    return (
      <MainWrapper>
        <RestaurantTitle>Restaurant to Transfer</RestaurantTitle>
        <TableWrapper>
          <div className="ag-theme-balham" style={{ width: '100%' }}>
            <AgGridReact
              columnDefs={this.columnRestaurantToTransferDefs}
              rowData={this.processedData}
              defaultColDef={this.defaultColDefProperty}
              domLayout="autoHeight"
            />
          </div>
        </TableWrapper>

        {this.props.transferType === 'to_reseller' && (
          <div>
            <p style={{ marginTop: '50px' }}>Staff Accounts</p>
            {this.state.gridTransferLoading && <RotateLoader size={2} />}
            {!this.state.gridTransferLoading && (
              <TableWrapper>
                <div className="ag-theme-balham" style={{ width: '85%', height }}>
                  <AgGridReact
                    columnDefs={this.columnStaffAccountsDefs}
                    rowData={this.state.staffAccounts}
                    defaultColDef={this.defaultColDefProperty}
                    domLayout={domlayout}
                  />
                </div>
              </TableWrapper>
            )}
          </div>
        )}
        <ButtonWrapper>
          <BackButton
            onClick={() => {
              this.props.setPage(1);
            }}
          >
            Back
          </BackButton>
          {this.state.loading && (
            <PrevewButton>
              <RotateLoader size={1} />
            </PrevewButton>
          )}
          {!this.state.loading && (
            <PrevewButton
              onClick={() => {
                this.restaurantTransfer();
              }}
            >
              Confirm
            </PrevewButton>
          )}
        </ButtonWrapper>
      </MainWrapper>
    );
  }
}

export default PreviewTransfer;
