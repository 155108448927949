import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { ViewAccounts } from './views/accounts';
import { ViewLogin } from './views/login';

function App() {
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [email, setEmail] = React.useState('');

  return (
    <ThemeProvider theme={theme}>
      <main id="scroll-root" className="mx-auto mt-10 px-4">
        <div className="bg-white px-8 py-10">
          {!isLoggedIn && <ViewLogin setLoggedIn={setLoggedIn} masterEmail={setEmail} />}
          {
            // @ts-ignore
            isLoggedIn && <ViewAccounts email={email} />
          }
        </div>
      </main>
    </ThemeProvider>
  );
}

export default App;
